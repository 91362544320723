import type { GetServerSideProps, GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import { Landing } from '../components/landing/Landing';
import { getCsrfToken, signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import Axios from 'axios';

const Home: NextPage<{ csrfToken: string; html: any }> = ({
  csrfToken,
  html,
}) => {
  const session = useSession();
  const router = useRouter();
  const t = useTranslations('Brand');

  if (
    session.status === 'authenticated' &&
    router.query.reason !== 'rejected'
  ) {
    router.replace('/offerings');
  } else if (router.query.reason === 'rejected') {
    signOut({ callbackUrl: '/?reason=rejected' });
  }

  return (
    <div>
      <Head>
        <title>{t('name')}</title>
        <meta name="description" content="Access Alternative Assets" />
      </Head>
      <Landing {...{ csrfToken, html }} />
    </div>
  );
};

const getCsrfTokenAndSetCookies = async ({ res, query }: any) => {
  // to make it work on Vercel
  const baseUrl =
    process.env.NEXTAUTH_URL || `https://${process.env.VERCEL_URL}`;
  // capturing the callback url if any, which should include the current domain for security ?
  const callbackUrlIsPresent = typeof query?.callbackUrl === 'string';
  const callbackUrlIsValid =
    callbackUrlIsPresent && query?.callbackUrl.startsWith(baseUrl);
  const host = callbackUrlIsValid ? query?.callbackUrl : baseUrl;
  const redirectURL = encodeURIComponent(host);
  // getting both the csrf form token and (next-auth.csrf-token cookie + next-auth.callback-url cookie)
  const csrfUrl = `${baseUrl}/api/auth/csrf?callbackUrl=${redirectURL}`;
  const csrfResponse = await fetch(csrfUrl);
  const json = await csrfResponse.json();
  let csrfToken = '';
  if (json.csrfToken) {
    csrfToken = json.csrfToken;
  }
  const { headers } = csrfResponse;
  // placing the cookies
  if (headers && headers.get('set-cookie')) {
    const [csrfCookie, redirectCookie]: any = headers
      .get('set-cookie')
      ?.split(',');
    res.setHeader('set-cookie', [csrfCookie, redirectCookie]);
  }
  // placing form csrf token
  return csrfToken;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  // const html = await getHTML();
  return {
    props: {
      html: '',
      csrfToken: await getCsrfTokenAndSetCookies(context),
      // csrfToken: await getCsrfToken(context),
      messages: {
        ...require(`../locales/${context.locale}/shared.json`),
        ...require(`../locales/${context.locale}/home.json`),
      },
      now: new Date().getTime(),
    },
  };
};

// async function getHTML() {
//   return Axios.get('https://assettribe.io/signup-waiver/')
//     .then((res: any) => res.data)
//     .catch((err) => console.log(err));
// }

export default Home;
