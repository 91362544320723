import { styled } from '@mui/material/styles';
import { colors } from 'src/constants/colors';
import { Button, ButtonProps } from '@mui/material';

interface LozengeButtonProps extends ButtonProps {
  backgroundInactive?: string;
  colorInactive?: string;
  shadowColor?: string;
  backgroundActive?: string;
  colorActive?: string;
}

export const colorsHeroBanner: LozengeButtonProps = {
  backgroundInactive: colors.white1,
  colorInactive: colors.darkBlack3,
  shadowColor: colors.pink,
  backgroundActive: colors.yellow,
};

export const colorsOfferCardHome: LozengeButtonProps = {
  shadowColor: colors.darkBlack1,
  colorActive: colors.darkBlack3,
  backgroundActive: colors.yellow,
};

export const LozengeButton = styled(Button, {
  shouldForwardProp: (prop) =>
    [
      'backgroundInactive',
      'colorInactive',
      'shadowColor',
      'backgroundActive',
      'colorActive',
    ].every((excludeProp) => prop !== excludeProp),
})<LozengeButtonProps>(
  ({
    theme,
    backgroundInactive,
    colorInactive,
    shadowColor,
    backgroundActive,
    colorActive,
  }) => ({
    borderRadius: ' 50px',
    backgroundColor: `${backgroundInactive || theme.palette.primary.main}`,
    boxShadow: `4px 4px 0 0 ${shadowColor || colors.white1}`,
    color: `${colorInactive || colors.white1}`,
    transition: ' all .3s',
    ':hover': {
      backgroundColor: `${backgroundActive || colors.white1} !important`,
      color: `${colorActive || colors.darkBlack3}`,
      boxShadow: 'none!important',
    },
  })
);
