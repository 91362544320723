import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Link from 'next/link';
import { useContext, useEffect, useRef, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { colors } from '../../constants/colors';
import { DescriptionText } from '../common/DescriptionText';
import { useRouter } from 'next/router';
import { useSnackAlert } from 'src/hooks/useSnackAlert';
import axios from 'axios';
import LinkedInLogin from 'src/pages/pocs/linkedIn-login';
import { StyledTextfield } from '../common/StyledTextfield';

// export const VerifyOtp = () => {
//   const [otp, setOtp] = useState('');
//   const formEl = useRef(null);

//   return (
//     <form
//       ref={formEl}
//       onSubmit={() => {
//         axios.get('/api/auth/csrf').then((res) => {
//           const ele: any = document.getElementById('csrfToken');
//           ele.value = res.data.csrfToken;
//           (formEl as any)?.current.submit();
//         });
//       }}
//       method="post"
//       action={`/api/auth/callback/credentials?callbackUrl=/offerings`}
//     >
//       <div>
//         <Typography variant={'h5'} mb={4}>
//           Verify OTP
//         </Typography>
//         <Typography sx={{ mb: 3 }}>
//           We have sent the verification code to your email Id. Please enter
//           below.
//         </Typography>
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           // name="email"
//           value={otp}
//           onChange={(e) => setOtp(e.target.value)}
//           // error={Boolean(formikProps.touched.email && formikProps.errors.email)}
//           // helperText={formikProps.touched.email && formikProps.errors.email}
//           placeholder={'Verification Code'}
//           style={{ marginBottom: 20, backgroundColor: colors.white1 }}
//         />
//         <Button sx={{ mb: 2 }} type={'submit'}>
//           SUBMIT OTP
//         </Button>

//         <Typography color="primary">Resend OTP?</Typography>
//       </div>
//     </form>
//   );
// };

export const LoginBox = ({
  openLoginModal,
  setOpenLoginModal,
  setOpenRegistrationModal,
  csrfToken,
}: any) => {
  const [email, setEmail] = useState('');
  const [password, setPassowrd] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showPassword, setShowPassowrd] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loginBtnDisabled, setLoginBtnDisabled] = useState(false);
  const formEl = useRef(null);

  const router = useRouter();

  const showSnackAlert = useSnackAlert();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // bgcolor: "background.paper",
    backgroundColor: 'black',
    // border: "2px solid #000",
    // boxShadow: 24,
    // p: 4,
    my: 2,
    overflowY: 'hidden',
    maxHeight: '80vh',
    outline: 0,
  };

  useEffect(() => {
    if (router.query.error === 'CredentialsSignin') {
      setOpenLoginModal(true);
      showSnackAlert('error', 'Invalid Credentials');
    }
  }, []);

  useEffect(() => {
    let registerSuccessEmail = sessionStorage.getItem('registerSuccessEmail');
    let registerSuccessPassword = sessionStorage.getItem(
      'registerSuccessPassword'
    );
    let fromRegister = sessionStorage.getItem('fromRegister');
    // if(!registerSuccessData){
    if (
      registerSuccessEmail &&
      registerSuccessPassword &&
      fromRegister === 'true'
    ) {
      setEmail(registerSuccessEmail);
      setPassowrd(registerSuccessPassword);
      setLoggedIn(true);
      showSnackAlert(
        'success',
        'Your One Time Passcode has been sent to your email address. Please check your inbox and/or spam folder.'
      );
      sessionStorage.removeItem('registerSuccessEmail');
      sessionStorage.removeItem('registerSuccessPassword');
      sessionStorage.removeItem('fromRegister');
    }
    // }
  }, [openLoginModal]);

  return (
    <Modal
      open={openLoginModal}
      onClose={() => setOpenLoginModal(false)}
      // BackdropProps={{ onClick: () => setOpenLoginModal(false) }}
      onBackdropClick={() => setOpenLoginModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form
        ref={formEl}
        onSubmit={(e) => {
          e.preventDefault();
          setLoginBtnDisabled(true);
          axios.get('/api/auth/csrf').then((res) => {
            const ele: any = document.getElementById('csrfToken');
            (document.getElementById('email') as any).disabled = false;
            (document.getElementById('password') as any).disabled = false;
            ele.value = res.data.csrfToken;
            (formEl as any)?.current.submit();
          });
        }}
        method="post"
        action={`/api/auth/callback/credentials?callbackUrl=/offerings`}
      >
        <Box sx={style}>
          <Paper
            sx={{
              p: 3,
              backgroundColor: 'black',
              // width: "30%",
              maxWidth: 400,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              borderColor: '#D4D5D966',
              textAlign: 'center',
              '::-webkit-scrollbar': {
                display: 'none',
              },
              // overflowY: 'scroll',
              // height: '800px',
            }}
            variant="outlined"
          >
            {currentPage === 1 ? (
              <>
                <Typography variant="h5" mb={3}>
                  LOGIN
                </Typography>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: 10,
                  }}
                >
                  <LinkedInLogin />
                </div>

                <Typography mb={2} variant="body1">
                  OR
                </Typography>

                <input
                  name="csrfToken"
                  id="csrfToken"
                  type="hidden"
                  defaultValue={csrfToken}
                />
                <StyledTextfield
                  required
                  disabled={loggedIn}
                  name="email"
                  label="Email"
                  id="email"
                  size="small"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={'Email Id'}
                  sx={{ marginBottom: 2 }}
                />
                <StyledTextfield
                  disabled={loggedIn}
                  name="password"
                  id="password"
                  label="Password"
                  required
                  placeholder="Password"
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassowrd(e.target.value)}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassowrd(!showPassword)}
                          onMouseDown={() => setShowPassowrd(false)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {loggedIn && (
                  <StyledTextfield
                    required
                    label="OTP"
                    name="otp"
                    placeholder="Enter OTP"
                    size="small"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    sx={{
                      marginTop: 1.5,
                      marginBottom: 2,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Resend a one time passcode to your email">
                            <IconButton
                              onClick={async () => {
                                if (!email || !password) {
                                  showSnackAlert(
                                    'error',
                                    'email password invalid!'
                                  );
                                } else {
                                  await axios.post('/api/otp', { email });
                                  setOtpSent(true);
                                  showSnackAlert(
                                    'success',
                                    'OTP sent successfully!'
                                  );
                                }
                              }}
                              // onMouseDown={() => setShowPassowrd(false)}
                              edge="end"
                            >
                              <Typography
                                sx={{ fontSize: 14 }}
                                color={'primary'}
                              >
                                RESEND OTP
                              </Typography>
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                {loggedIn ? (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loginBtnDisabled}
                  >
                    LOGIN
                  </Button>
                ) : (
                  <Button
                    disabled={loginBtnDisabled}
                    type={loggedIn && otp.length === 6 ? 'submit' : 'button'}
                    onClick={async () => {
                      if (otp.length === 6) return null;
                      if (!email || !password) {
                        showSnackAlert(
                          'error',
                          'Email or password cannot be left blank'
                        );
                        return;
                      }
                      const checkPasswordResponse = await axios.post(
                        '/api/auth/checkPassword',
                        {
                          email,
                          password,
                        }
                      );
                      if (
                        checkPasswordResponse?.data?.isPasswordValid === false
                      ) {
                        showSnackAlert('error', 'Invalid Password!');
                        return;
                      }
                      setLoginBtnDisabled(true);
                      await axios.post('/api/otp', {
                        email,
                      });
                      setLoginBtnDisabled(false);
                      // setCurrentPage(2)
                      // return;
                      if (!loggedIn) {
                        setLoggedIn(true);
                        showSnackAlert(
                          'success',
                          'Your One Time Passcode has been sent to your email address. Please check your inbox and/or spam folder.'
                        );
                      }
                      if (loggedIn && otp.length < 6) {
                        showSnackAlert('error', 'OTP should be 6 digits');
                      }
                    }}
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    LOGIN
                  </Button>
                )}

                <Typography
                  onClick={async (e) => {
                    if (email) {
                      await axios.post('/api/auth/password-reset-mail', {
                        emailId: email,
                      });
                      showSnackAlert(
                        'success',
                        'Email has been sent with a link to reset your password!'
                      );
                    } else {
                      showSnackAlert('error', 'Please enter email id!');
                    }
                  }}
                  color={'primary'}
                  sx={{ mt: 1, mb: 4, cursor: 'pointer' }}
                >
                  Forgot Password ?
                </Typography>
                {/* <Typography
            color={colors.white1}
            fontSize={12}
            variant="body2"
            mb={3}
          >

            By proceeding you agree and accept to adhere all our{" "}
            <a
              href={"https://assettribe.io/terms-of-use"}
              style={{
                display: "inline",
                cursor: "pointer",
                color: colors.pink,
                textDecoration: "none",
                fontSize: 12,
              }}
            >
              Terms & Conditions
            </a>
            .
          </Typography> */}
                <Divider color={'gray'} sx={{ mb: 1 }} />
                <Typography fontSize={14} color={colors.white1}>
                  Don&apos;t have an account!{' '}
                  {/* <Link passHref={true} href="/offerings"> */}
                  <span
                    onClick={() => {
                      setOpenLoginModal(false);
                      setOpenRegistrationModal(true);
                    }}
                    style={{ color: colors.pink, cursor: 'pointer' }}
                  >
                    REGISTER NOW
                  </span>
                  {/* </Link> */}
                </Typography>
              </>
            ) : null}
          </Paper>
        </Box>
      </form>
    </Modal>
  );
};
