import { LandingHeader } from './Header';
import { RegisterBoxFull } from './RegisterBox';
import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  colorsHeroBanner,
  LozengeButton as Button,
} from '../common/LozengeButton';
import { colors } from '../../constants/colors';
import React, { useEffect, useState } from 'react';
import { LoginBox } from './LoginBox';
import { CookiesModal } from './CookiesModal';
import { LandingFooter } from './Footer';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { useSnackAlert } from 'src/hooks/useSnackAlert';
import { event } from 'src/lib/gtag';

export const Landing = ({ csrfToken, html }: any) => {
  const [openRegistrationModal, setOpenRegistrationModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openCookieModal, setOpenCookieModal] = useState(false);

  const smallScreen = useMediaQuery('(max-width:800px)');

  const router = useRouter();
  const showSnackAlert = useSnackAlert();
  const t2 = useTranslations('Content');
  const t3 = useTranslations('Button');
  
  useEffect(() => {
    if (router.query?.reason === 'rejected') {
      showSnackAlert(
        'error',
        'The investments available on AssetTribe can be illiquid, volatile and sometimes complex. We do not feel we currently offer any investments that are appropriate for you. Please contact invest@assettribe.io for any further assistance.',
        10000
      );
    }
  }, [router.query.reason]);

  useEffect(() => {
    const cookiePopupDate = localStorage.getItem('cookiePopup');
    if (!cookiePopupDate) return setOpenCookieModal(true);
  }, []);

  useEffect(() => {
    if (router.query.register) {
      setOpenRegistrationModal(true);
    }
  }, [router.query]);

  const buttonStyles: SxProps = { mt: 0, paddingInline: smallScreen ? "1.5em" : "3em", fontSize: '1.1rem' };

  return (
    <>
      {/* section-1 Moto and Registration */}
      <div
        style={{
          position: 'relative',
        }}
      >
        <video
          autoPlay={true}
          muted={true}
          loop={true}
          src="https://assettribe.io/wp-content/uploads/2022/09/20220919-AssetTribe-hero.mp4"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: '-1',
            backgroundColor: '#0a0c0e',
          }}
        ></video>

        <LandingHeader {...{ openLoginModal, setOpenLoginModal }} />
        <CookiesModal {...{ openCookieModal, setOpenCookieModal }} />
        <Box
          sx={{
            px: smallScreen ? 4 : 13,
            height: '94vh'
          }}
        >
          <div style={{ textAlign: 'center', width: '100%', paddingBottom: "25%", paddingTop: "17%" }}>
            <Typography
              color={colors.yellow}
              variant="h3"
              fontWeight={700}
              marginBottom={4}
            >
              ACCESS ALTERNATIVE ASSETS
            </Typography>
            <Typography sx={{ mb: 5 }} style={{}}>
              {t2('description')}
            </Typography>
            <br />
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                columnGap: 2,
                rowGap: 4,
                mt: 4
              }}
            >
              <Button
                color="secondary"
                onClick={() => {
                  event({
                    action: 'login_clicked',
                    category: 'auth',
                    label: '',
                    value: '',
                  });
                  setOpenLoginModal(true);
                }}
                sx={buttonStyles}
                {...colorsHeroBanner}
              >
                {t3('login')}
              </Button>
              <Button
                onClick={() => {
                  event({
                    action: 'register_clicked',
                    category: 'auth',
                    label: '',
                    value: '',
                  });
                  setOpenRegistrationModal(true);
                }}
                sx={buttonStyles}
                {...colorsHeroBanner}
              >
                {t3('registerNow')}
              </Button>
            </Box>
          </div>
        </Box>
      </div>
      <div>
        <RegisterBoxFull
          {...{
            openRegistrationModal,
            setOpenRegistrationModal,
            setOpenLoginModal,
            html,
          }}
        />
        <LoginBox
          {...{
            openLoginModal,
            setOpenLoginModal,
            setOpenRegistrationModal,
            csrfToken,
          }}
        />
        {/* )} */}
      </div>

      {/* section-5 Footer */}
      <LandingFooter />
    </>
  );
};
