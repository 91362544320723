import {
  Box,
  Button,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { colors } from '../../constants/colors';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

export const CookiesModal = ({ openCookieModal, setOpenCookieModal }: any) => {
  const modalBreak = useMediaQuery('(max-width:1382px)');
  const t = useTranslations('Content');
  const t1 = useTranslations('Button');

  const router = useRouter();

  const style = {
    position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // my: 2,
    bottom: 0,
    width: '100%',
    overflowY: 'scroll',
    maxHeight: '80vh',
    outline: 0,
  };

  return (
    <Modal
      open={openCookieModal}
      //   onClose={() => setOpenCookieModal(false)}
      // BackdropProps={{ onClick: () => setOpenCookieModal(false) }}
      //   onBackdropClick={() => setOpenCookieModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Paper
          sx={{
            p: 3,
            px: 5,
            backgroundColor: '#000000BF',
            // width: "30%",
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            rowGap: 2,
            columnGap: 3,
            // flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: '#D4D5D966',
            // textAlign: "center",
            '::-webkit-scrollbar': {
              display: 'none',
            },
            // overflowY: 'scroll',
            // height: '800px',
          }}
          variant="outlined"
        >
          <Typography sx={{ ...(!modalBreak && { flex: 6 }) }}>
            {t('cookiesModalText')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              rowGap: 3,
              columnGap: 3,
              ...(!modalBreak && { flex: 4 }),
            }}
          >
            <Link href={'/wp-pages/legal'} target="_blank">
              <a target={'_blank'}>
                <Button
                  sx={{ width: 250 }}
                  color="secondary"
                  variant="outlined"
                >
                  {t1('learnMore')}
                </Button>
              </a>
            </Link>
            <Button
              onClick={() => {
                setOpenCookieModal(false);
                localStorage.setItem('cookiePopup', new Date().toJSON());
              }}
              sx={{ width: 250 }}
            >
              {t('cookiesBtnAccept')}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};
